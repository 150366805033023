import { Component, OnInit } from '@angular/core';
import { MouseEvent } from '@agm/core';
import { Http, Headers, Response, URLSearchParams,RequestOptions } from '@angular/http';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import 'rxjs/add/operator/toPromise';
// just an interface for type safety.
import { ToastrManager } from 'ng6-toastr-notifications';

interface marker {
	lat: number;
	lng: number;
	label?: string;
	draggable: boolean;
}
declare const google: any;

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss']
})
export class ContactsComponent implements OnInit {
  zoom: number = 18;
    lat: number = 24.673710;
    lng: number = 46.733766;
    styles: any[] = [{"featureType":"water","elementType":"geometry","stylers":[{"color":"#e9e9e9"},{"lightness":17}]},{"featureType":"landscape","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":20}]},{"featureType":"road.highway","elementType":"geometry.fill","stylers":[{"color":"#ffffff"},{"lightness":17}]},{"featureType":"road.highway","elementType":"geometry.stroke","stylers":[{"color":"#ffffff"},{"lightness":29},{"weight":0.2}]},{"featureType":"road.arterial","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":18}]},{"featureType":"road.local","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":16}]},{"featureType":"poi","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":21}]},{"featureType":"poi.park","elementType":"geometry","stylers":[{"color":"#dedede"},{"lightness":21}]},{"elementType":"labels.text.stroke","stylers":[{"visibility":"on"},{"color":"#ffffff"},{"lightness":16}]},{"elementType":"labels.text.fill","stylers":[{"saturation":36},{"color":"#333333"},{"lightness":40}]},{"elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"transit","elementType":"geometry","stylers":[{"color":"#f2f2f2"},{"lightness":19}]},{"featureType":"administrative","elementType":"geometry.fill","stylers":[{"color":"#fefefe"},{"lightness":20}]},{"featureType":"administrative","elementType":"geometry.stroke","stylers":[{"color":"#fefefe"},{"lightness":17},{"weight":1.2}]}];
    focus;
    focus1;
    focus2;
    focus3;
    focus4;
    panelOpenState = false;

    //varribale relate to mail send
    email:any;
    value:any;
    femail='dinu.kr70@gmail.com';
    name:any;
    subject:any;
    firstName:any;
    lastName:any;
    file:any;
    fileString:any;
    fileObj={
    filename:'',
    content: '',
    encoding: 'base64'
  }
    constructor(private http:Http,public toastr: ToastrManager) { }
   // google maps zoom level


  clickedMarker(label: string, index: number) {
    console.log(`clicked the marker: ${label || index}`)
  }
  
  mapClicked($event: MouseEvent) {
    this.markers.push({
      lat: $event.coords.lat,
      lng: $event.coords.lng,
      draggable: true
    });
  }
  
  markerDragEnd(m: marker, $event: MouseEvent) {
    console.log('dragEnd', m, $event);
  }
  
  markers: marker[] = [
	  {
		  lat: 51.673858,
		  lng: 7.815982,
		  label: 'A',
		  draggable: true
	  },
	  {
		  lat: 51.373858,
		  lng: 7.215982,
		  label: 'B',
		  draggable: false
	  },
	  {
		  lat: 51.723858,
		  lng: 7.895982,
		  label: 'C',
		  draggable: true
	  }
  ]



    ngOnInit() {
      
      var myLatlng = new google.maps.LatLng(24.673710, 46.733766);
      var mapOptions = {
        // zoom: 14,
        center: myLatlng,
        styles: [{"featureType":"water","elementType":"geometry","stylers":[{"color":"#e9e9e9"},{"lightness":17}]},{"featureType":"landscape","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":20}]},{"featureType":"road.highway","elementType":"geometry.fill","stylers":[{"color":"#ffffff"},{"lightness":17}]},{"featureType":"road.highway","elementType":"geometry.stroke","stylers":[{"color":"#ffffff"},{"lightness":29},{"weight":0.2}]},{"featureType":"road.arterial","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":18}]},{"featureType":"road.local","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":16}]},{"featureType":"poi","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":21}]},{"featureType":"poi.park","elementType":"geometry","stylers":[{"color":"#dedede"},{"lightness":21}]},{"elementType":"labels.text.stroke","stylers":[{"visibility":"on"},{"color":"#ffffff"},{"lightness":16}]},{"elementType":"labels.text.fill","stylers":[{"saturation":36},{"color":"#333333"},{"lightness":40}]},{"elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"transit","elementType":"geometry","stylers":[{"color":"#f2f2f2"},{"lightness":19}]},{"featureType":"administrative","elementType":"geometry.fill","stylers":[{"color":"#fefefe"},{"lightness":20}]},{"featureType":"administrative","elementType":"geometry.stroke","stylers":[{"color":"#fefefe"},{"lightness":17},{"weight":1.2}]}],
        scrollwheel: false, //we disable de scroll over the map, it is a really annoing when you scroll through page
      }
      
      var map = new google.maps.Map(document.getElementById("contactUsMap2"), mapOptions);
      
      var marker = new google.maps.Marker({
          position: myLatlng,
          title:"Suhaib Sultan Office"
      });
      
      // To add the marker to the map, call setMap();
      marker.setMap(map);
    }


    sendEmail() {
      this.name=this.firstName+" "+this.lastName;
      let url = `https://us-central1-amnwwafaa-82e37.cloudfunctions.net/sendMail`
    
      let headers = new Headers({'Content-Type': 'application/json' });
      let options: RequestOptions = new RequestOptions({ headers: headers });
     
    
      let body={
        "dest":this.email?this.email:'',
        "org" : this.femail?this.femail:'',
        "subject" :this.value?this.value:'',
        "name" :this.name?this.name:'',
        "fileObj":this.fileObj?this.fileObj:{}
      }
      console.log("body",body);
      return this.http.post(url, body, options)
                      .subscribe(res => {
                        console.log(res)
                        this.showSuccess()
                      },err=>{

                      })
                      
   
    
    }

    onFileChange(event){
      console.log(event)
      let myReader = new FileReader();
      if (event.target.files && event.target.files.length > 0) {
         this.file = event.target.files[0];
         this.fileObj['filename']=this.file.name;
        myReader.onload = this._handleReaderLoaded.bind(this);
        myReader.readAsBinaryString(this.file);
      }
    }
      _handleReaderLoaded(readerEvt) {
        // console.log(1)
        
        var binaryString = readerEvt.target.result;
        let base64textString = btoa(binaryString);
        let filevalue = btoa(binaryString);
        this.fileString=filevalue;
        this.fileObj['content']=this.fileString;
    
    }
    showSuccess() {
      this.toastr.successToastr('This is success toast.', 'Success!');
  }
}
