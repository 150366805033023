import { HttpClient } from '@angular/common/http';
import { LanguageHelper } from 'app/shared/language/language.helper';
import { Component, OnInit } from '@angular/core';
import { MouseEvent } from '@agm/core';
import * as Rellax from 'rellax';
import { Router } from '@angular/router';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { Http, Headers, Response, URLSearchParams,RequestOptions } from '@angular/http';
var easeInOutQuad = function (t, b, c, d) {
  t /= d/2;
    if (t < 1) return c/2*t*t + b;
    t--;
    return -c/2 * (t*(t-2) - 1) + b;
};
interface marker {
	lat: number;
	lng: number;
	label?: string;
	draggable: boolean;
}
declare const google: any;


@Component({
  selector: 'app-hq',
  templateUrl: './hq.component.html',
  styleUrls: ['./hq.component.scss']
})
export class HqComponent implements OnInit {

  constructor(private languageHelper:LanguageHelper,
     private router:Router,
    private modalService: NgbModal,
    private httpClient:HttpClient,) { }
  isRTL:any;
  english:any;
  arabic:any;
  private dataApi:string="assets/datajsons/locations";
  private api:any;
  hqs=[];
  zoom: number = 18;
  lat: number = 24.673710;
  lng: number = 46.733766;
  styles: any[] = [{"featureType":"water","elementType":"geometry","stylers":[{"color":"#e9e9e9"},{"lightness":17}]},{"featureType":"landscape","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":20}]},{"featureType":"road.highway","elementType":"geometry.fill","stylers":[{"color":"#ffffff"},{"lightness":17}]},{"featureType":"road.highway","elementType":"geometry.stroke","stylers":[{"color":"#ffffff"},{"lightness":29},{"weight":0.2}]},{"featureType":"road.arterial","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":18}]},{"featureType":"road.local","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":16}]},{"featureType":"poi","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":21}]},{"featureType":"poi.park","elementType":"geometry","stylers":[{"color":"#dedede"},{"lightness":21}]},{"elementType":"labels.text.stroke","stylers":[{"visibility":"on"},{"color":"#ffffff"},{"lightness":16}]},{"elementType":"labels.text.fill","stylers":[{"saturation":36},{"color":"#333333"},{"lightness":40}]},{"elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"transit","elementType":"geometry","stylers":[{"color":"#f2f2f2"},{"lightness":19}]},{"featureType":"administrative","elementType":"geometry.fill","stylers":[{"color":"#fefefe"},{"lightness":20}]},{"featureType":"administrative","elementType":"geometry.stroke","stylers":[{"color":"#fefefe"},{"lightness":17},{"weight":1.2}]}];
  focus;
  focus1;
  focus2;
  focus3;
  focus4;
  panelOpenState = false;
  branch:any;
  
  ngOnDestroy(){
    var body = document.getElementsByTagName('body')[0];
    body.classList.remove('contact-page');
    var navbar = document.getElementsByTagName('nav')[0];
    navbar.classList.remove('navbar-transparent');

}
  clickedMarker(label: string, index: number) {
    console.log(`clicked the marker: ${label || index}`)
  }
  
  mapClicked($event: MouseEvent) {
    this.markers.push({
      lat: $event.coords.lat,
      lng: $event.coords.lng,
      draggable: true
    });
  }
  
  markerDragEnd(m: marker, $event: MouseEvent) {
    console.log('dragEnd', m, $event);
  }
  
  markers: marker[] = [
    {
      lat: 51.673858,
      lng: 7.815982,
      label: 'A',
      draggable: true
    },
    {
      lat: 51.373858,
      lng: 7.215982,
      label: 'B',
      draggable: false
    },
    {
      lat: 51.723858,
      lng: 7.895982,
      label: 'C',
      draggable: true
    }
  ]
  ngOnInit() {
    var rellaxHeader = new Rellax('.rellax-header');

    var body = document.getElementsByTagName('body')[0];
    body.classList.add('contact-page');
    var navbar = document.getElementsByTagName('nav')[0];
    navbar.classList.add('navbar-transparent');


    this.getAllLangs();

    this.getHQ();
    for(var data in this.branch){
      let obj={ 
        lat: this.branch[data].lat,
        lng: this.branch[data].lng,
        label: this.branch[data].name.charAt(0),
        draggable: true
      }
      let mark=[];
      mark.push(obj);
      this.branch[data]['markers']=mark

    }
  }
  changeLang(lang) {
    // alert();
    this.languageHelper.changeLang(lang);
    window.location.reload();
    
  
  
  }
  getAllLangs() {
    console.log("this.languageHelper.getAllLanguages();",this.languageHelper.getAllLanguages())
    let arr=this.languageHelper.getAllLanguages();
    this.english=arr[0];
    this.arabic=arr[1];
    return this.languageHelper.getAllLanguages();
  }
   
  getHQ(){
    let api=this.isRTL?this.dataApi+"/location_ar.json":this.dataApi+"/location_en.json";
    this.httpClient.get<any>(api).subscribe(res=>{
     console.log(res);
     this.hqs=res.headQuaters;
   })
   }
}
