import { LanguageHelper } from './../../shared/language/language.helper';
import { Component, OnInit } from '@angular/core';
import { Http, Headers, Response, URLSearchParams,RequestOptions } from '@angular/http';
import { HttpClient, HttpHeaders } from "@angular/common/http";
@Component({
  selector: 'app-achivment',
  templateUrl: './achivment.component.html',
  styleUrls: ['./achivment.component.scss']
})
export class AchivmentComponent implements OnInit {
  isRTL:any;
  private dynamicDatApi:string="assets/datajsons/achivement";
  private api:any;
  private achivarrays=[];
  constructor(private languageHelper:LanguageHelper,private httpClient:HttpClient) { 
    this.isRTL = this.languageHelper.currentLang().isRTL;

  }

  ngOnInit() {
    this.api=this.isRTL?this.dynamicDatApi+"/achivement_ar.json":this.dynamicDatApi+"/achivement_en.json";
    this.getAchiventdata(this.api);
   
  }
  

  getAchiventdata(api){
    this.httpClient.get<any>(api).subscribe(res=>{
      console.log(res);
      this.achivarrays=res;
    })
  }

}
