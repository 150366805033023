import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import * as AOS from 'aos';
import { LanguageHelper } from 'app/shared/language/language.helper';
import { Http, Headers, Response, URLSearchParams,RequestOptions } from '@angular/http';
import { HttpClient, HttpHeaders } from "@angular/common/http";
@Component({
  selector: 'app-introductions',
  templateUrl: './introductions.component.html',
  styleUrls: ['./introductions.component.scss']
})
export class IntroductionsComponent implements OnInit {
  isRTL:any;//chnage direction variable

  constructor( private languageHelper:LanguageHelper,
    private httpClient:HttpClient,private router:Router) {
 this.isRTL = this.languageHelper.currentLang().isRTL;
}

  ngOnInit() {
  }

}
