import { Inject, Injectable,RendererFactory2, Renderer2 } from "@angular/core";
import { LANGUAGES } from "./language.constant";
import { DOCUMENT } from '@angular/platform-browser';
import { LocalStorageService } from 'ngx-webstorage';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class LanguageHelper{
    constructor(
        @Inject(DOCUMENT) private document,
        private localStorageService: LocalStorageService,
        private translateService: TranslateService
    ){

    }

    getAllLanguages():any[]{
        return LANGUAGES;
    }
    

  changeLang(lang: any){
    this.localStorageService.store('lang',lang.name);
    this.translateService.use(lang.name);
    
  }

  currentLang():any{
    let stored = this.localStorageService.retrieve('lang');
    let current = this.translateService.currentLang;
    let optLangs = [
      { name:'en_US',title:'English',isRTL:false,icon:'assets/img/flags/SA.png'},
      { name:'ar_SA',title:'عربي',isRTL:true,icon:'assets/img/flags/SA.png'}
    ];
    if (stored){
      current = stored;
    }
    if (!current){
      current = 'en';
    }
    let lang = LANGUAGES.find(cur=>cur.name === current);
    if(!lang) {
      lang = optLangs.find(cur=>cur.name === current);
      if(lang.name=='en_US') lang.name='en';
      if(lang.name=='ar_SA') lang.name='ar';
    }
    return lang;
  }

}