import { Component, OnInit ,ViewChild} from '@angular/core';
import * as AOS from 'aos';
import { LanguageHelper } from 'app/shared/language/language.helper';
import { Http, Headers, Response, URLSearchParams,RequestOptions } from '@angular/http';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { NgImageSliderModule, NgImageSliderComponent } from 'ng-image-slider';
import { Router } from '@angular/router';
@Component({
  selector: 'app-client',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.scss']
})
export class ClientComponent implements OnInit {
  private dynamicDatApi:string;
  private dataApi:string="assets/datajsons"
  @ViewChild('nav') ds: NgImageSliderComponent;
  @ViewChild('nav1') ds1: NgImageSliderComponent;
  title = 'Ng Image Slider';
   sliders=[];
   imageWidth={width: 400, height: 300};
   mediaData:any;
   isRTL:any;//chnage direction variable
   icon_video_button_class: string = 'fa-play';
   text_video_button: string = 'Play Video';
   showSlider = true;

   sliderWidth: Number = 1180;
   sliderImageWidth: Number = 170;
   sliderImageHeight: Number = 150;
   sliderArrowShow: Boolean = true;
   sliderInfinite: Boolean = true;
   sliderImagePopup: Boolean = false;
   sliderAutoSlide: Boolean = true;
   sliderSlideImage: Number = 1;
   sliderAnimationSpeed: any = 5;

   imageClientObject: Array<object> = [
 
];
imagepartnerObject: Array<object> = [
 
];


 constructor( private languageHelper:LanguageHelper,
       private httpClient:HttpClient,private router:Router) {
    this.isRTL = this.languageHelper.currentLang().isRTL;
  }

  ngOnInit() {
    AOS.init();
    this.getMediaData();
  }
   //getMediaData
 getMediaData(){
 
  this.httpClient.get<any>(`${this.dataApi}/client-product.json`).subscribe(res=>{
    console.log("rs",res);
    this.mediaData=res;
    for(var data in this.mediaData.client){
      this.imageClientObject.push({ 
        image: this.mediaData.client[data].path,
        thumbImage: this.mediaData.client[data].path,
        
      },)
    }
    for(var data in this.mediaData.partner){
      this.imagepartnerObject.push({ 
        image: this.mediaData.partner[data].path,
        thumbImage: this.mediaData.partner[data].path,
        
      },)
    }
  })
}

}
