import { MediaComponent } from './media.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { AgmCoreModule } from '@agm/core';
import { ToastrModule } from 'ng6-toastr-notifications';
import { LightboxModule } from 'ngx-lightbox';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import {MatExpansionModule} from '@angular/material/expansion';
import { SliderModule } from 'angular-image-slider';

import { DragScrollModule } from 'ngx-drag-scroll';

@NgModule({
    imports: [
        CommonModule,
        NgbModule,
        FormsModule,
    
        RouterModule,
         ToastrModule.forRoot(),
         LightboxModule,
         TranslateModule,
         SliderModule,
         DragScrollModule

    ],
    declarations: [
        MediaComponent
 
    ],
    exports: [
        MediaComponent
    ]
})
export class MediaModule { }
