import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SectionsComponent } from './sections.component';
import { HeadersComponent } from './headers/headers.component';

import { ContactsComponent } from './contacts/contacts.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { AgmCoreModule } from '@agm/core';
import { ToastrModule } from 'ng6-toastr-notifications';
import { LightboxModule } from 'ngx-lightbox';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import {MatExpansionModule} from '@angular/material/expansion';
import { NgImageSliderModule } from 'ng-image-slider';

import bootstrap from "bootstrap";
import { ClientComponent } from './client/client.component';
import { AchivmentComponent } from './achivment/achivment.component';
import { ServicesComponent } from './services/services.component';
import { BranchComponent } from './branch/branch.component';
import { IntroductionsComponent } from './introductions/introductions.component';
import { Header1Component } from './header1/header1.component';
import { NewsletterComponent } from './newsletter/newsletter.component';


@NgModule({
    imports: [
        CommonModule,
        NgbModule,
        FormsModule,
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyDLighMISUlDdfJn4IswlxZRSICOHi1SEk'
        }),
        RouterModule,
         ToastrModule.forRoot(),
         LightboxModule,
         TranslateModule,
         NgImageSliderModule

    ],
    declarations: [
        SectionsComponent,
        HeadersComponent,
 
        ContactsComponent,
 
        ClientComponent,
 
        AchivmentComponent,
 
        ServicesComponent,
 
        BranchComponent,
 
        IntroductionsComponent,
 
        Header1Component,
 
        NewsletterComponent,
 
    ],
    exports: [
        SectionsComponent
    ]
})
export class SectionsModule { }
