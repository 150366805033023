import { Router } from '@angular/router';
import { Component, OnInit ,ViewChild} from '@angular/core';
import * as AOS from 'aos';
import { LanguageHelper } from 'app/shared/language/language.helper';
import { Http, Headers, Response, URLSearchParams,RequestOptions } from '@angular/http';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { NgImageSliderModule, NgImageSliderComponent } from 'ng-image-slider';

var easeInOutQuad = function (t, b, c, d) {
  t /= d/2;
    if (t < 1) return c/2*t*t + b;
    t--;
    return -c/2 * (t*(t-2) - 1) + b;
};
declare const $: any;
@Component({
  selector: 'app-header1',
  templateUrl: './header1.component.html',
  styleUrls: ['./header1.component.scss']
})
export class Header1Component implements OnInit {
  private dynamicDatApi:string="assets/i18n";
  private dataApi:string="assets/datajsons";
  @ViewChild('nav') ds: NgImageSliderComponent;
  title = 'Ng Image Slider';
   sliders=[];
   imageWidth={width: 400, height: 300};
   mediaData:any;
   isRTL:any;//chnage direction variable
   icon_video_button_class: string = 'fa-play';
   text_video_button: string = 'Play Video';
   showSlider = true;

   sliderWidth: Number = 940;
   sliderImageWidth: Number = 300;
   sliderImageHeight: Number = 225;
   sliderArrowShow: Boolean = true;
   sliderInfinite: Boolean = false;
   sliderImagePopup: Boolean = true;
   sliderAutoSlide: Boolean = false;
   sliderSlideImage: Number = 1;
   sliderAnimationSpeed: any = 1;
   
   english:any;
   arabic:any;

   imageObject: Array<object> = [
 
];


 constructor( private languageHelper:LanguageHelper,
       private httpClient:HttpClient,private router:Router) {
    this.isRTL = this.languageHelper.currentLang().isRTL;
    this.getAllLangs();
    console.log("isRtl",this.isRTL)
  }

   media=[1,2,3,4,5,6,7,8,9,1,2,3,4,5,6,7,8,9,1,2,3,4,5,6,7,8,9,1,2,3,4,5,6,7,8,9]

  medias=[]

 ngOnInit() {
        
 
  this.getMediaData();
   $('.responsive').slick({
     dots: true,
     prevArrow: $('.prev'),
     nextArrow: $('.next'),
     infinite: false,
     speed: 300,
     slidesToShow: 4,
     slidesToScroll: 4,
     responsive: [
       {
         breakpoint: 1024,
         settings: {
           slidesToShow: 3,
           slidesToScroll: 3,
           infinite: true,
           dots: true
         }
       },
       {
         breakpoint: 600,
         settings: {
           slidesToShow: 2,
           slidesToScroll: 2
         }
       },
       {
         breakpoint: 480,
         settings: {
           slidesToShow: 1,
           slidesToScroll: 1
         }
       }
       // You can unslick at a given breakpoint now by adding:
       // settings: "unslick"
       // instead of a settings object
     ]
   });
   AOS.init();

           console.log(this.languageHelper.currentLang());
       
      
        this.isRTL = this.languageHelper.currentLang().isRTL;
      
        this.getData(this.isRTL);

 } 

 playBackgroundVideo(event){
     var id_video = document.getElementById('video-source') as HTMLVideoElement;
     var parent = event.target.parentElement.parentElement;
   if(id_video.paused){
        id_video.play();
       this.text_video_button = 'Pause Video';
       this.icon_video_button_class = 'fa-pause';
    } else {
        id_video.pause();
       this.text_video_button = 'Play Video';
       this.icon_video_button_class = 'fa-play';
    }
 }


 //labgyage change in header
 getAllLangs() {
   console.log("this.languageHelper.getAllLanguages();",this.languageHelper.getAllLanguages())
   let arr=this.languageHelper.getAllLanguages();
   this.english=arr[0];
   this.arabic=arr[1];
   return this.languageHelper.getAllLanguages();
}

changeLang(lang) {
   // alert();
   this.languageHelper.changeLang(lang);
   window.location.reload();
   
 

}



getData(isRtls){
 let url=isRtls?`${this.dynamicDatApi}/ar.json`:`${this.dynamicDatApi}/en.json`;
    this.httpClient.get<any>(url).subscribe(res=>{
        console.log(res);
        this.sliders=res.SLIDERS;

    })

}


//getMediaData
getMediaData(){

  this.httpClient.get<any>(`${this.dataApi}/media.json`).subscribe(res=>{
    console.log("rs",res);
    this.mediaData=res;

  })
}

smoothScroll(target) {
 var targetScroll = document.getElementById(target);
 this.scrollTo(document.scrollingElement || document.documentElement, targetScroll.offsetTop, 1250);
}
scrollTo(element, to, duration) {
 var start = element.scrollTop,
     change = to - start,
     currentTime = 0,
     increment = 20;

 var animateScroll = function(){
     currentTime += increment;
     var val = easeInOutQuad(currentTime, start, change, duration);
     element.scrollTop = val;
     if(currentTime < duration) {
         setTimeout(animateScroll, increment);
     }
 };
 animateScroll();
}
gotoHeroes() {

 this.router.navigate(['/media']);
 location.reload();

}
}
