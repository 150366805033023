import { Component, OnInit } from '@angular/core';
import * as AOS from 'aos';
import { LanguageHelper } from 'app/shared/language/language.helper';
import { Http, Headers, Response, URLSearchParams,RequestOptions } from '@angular/http';
import { HttpClient, HttpHeaders } from "@angular/common/http";
var easeInOutQuad = function (t, b, c, d) {
  t /= d/2;
    if (t < 1) return c/2*t*t + b;
    t--;
    return -c/2 * (t*(t-2) - 1) + b;
};
@Component({
  selector: 'app-media',
  templateUrl: './media.component.html',
  styleUrls: ['./media.component.scss']
})
export class MediaComponent implements OnInit {
media=[200,201,202,203,200,201,202,203,200,201,202,203,200,201,202,203,4,5,6,7,8,9,10,,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,1,2,3,4,5,6,7,8,9,10,,11,12,13,14,15,16]
 suhaib =[]
private dataApi:string="assets/datajsons"
 mediaData:any;
 english:any;
 arabic:any;
 isRTL:any;
  constructor( private httpClient:HttpClient,private languageHelper:LanguageHelper,) {
    this.isRTL = this.languageHelper.currentLang().isRTL;
   }
   public imagesUrl;

  ngOnInit() {
    this.imagesUrl = [];
    this.getMediaData();
    this.getAllLangs();
  
  }

  getMediaData(){
 
    this.httpClient.get<any>(`${this.dataApi}/media.json`).subscribe(res=>{
      console.log("rs",res);
      this.mediaData=res;
      this.media=this.mediaData.media;
 
    })
  }
  smoothScroll(target) {
    var targetScroll = document.getElementById(target);
    this.scrollTo(document.scrollingElement || document.documentElement, targetScroll.offsetTop, 1250);
}
scrollTo(element, to, duration) {
    var start = element.scrollTop,
        change = to - start,
        currentTime = 0,
        increment = 20;

    var animateScroll = function(){
        currentTime += increment;
        var val = easeInOutQuad(currentTime, start, change, duration);
        element.scrollTop = val;
        if(currentTime < duration) {
            setTimeout(animateScroll, increment);
        }
    };
    animateScroll();
}


changeLang(lang) {
  // alert();
  this.languageHelper.changeLang(lang);
  window.location.reload();
  


}

getAllLangs() {
  console.log("this.languageHelper.getAllLanguages();",this.languageHelper.getAllLanguages())
  let arr=this.languageHelper.getAllLanguages();
  this.english=arr[0];
  this.arabic=arr[1];
  return this.languageHelper.getAllLanguages();
}
}
