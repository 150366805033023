import { LocalStorageService } from 'ngx-webstorage';
import { Component, OnInit } from '@angular/core';
import { LanguageHelper } from './../../shared/language/language.helper';
import { HttpClient, HttpHeaders } from "@angular/common/http";
@Component({
  selector: 'app-newsletter',
  templateUrl: './newsletter.component.html',
  styleUrls: ['./newsletter.component.scss']
})
export class NewsletterComponent implements OnInit {
  closes=true;
  isRTL:any;
  news:any;
  private dataApi:string="assets/datajsons/newsletter";
  constructor(private languageHelper:LanguageHelper,
   private httpClient:HttpClient,private localStorageService:LocalStorageService) { }

  ngOnInit() {
    this.isRTL=this.languageHelper.currentLang().isRTL;
    this.localStorageService.store('popup',this.news);
      this.getNews();
  }
  close(){
    this.closes=false;
    this.news.visible=false;
    this.localStorageService.store('popup',this.news);
  }

  
  
  getNews(){
    let api=this.isRTL?this.dataApi+"/nl_ar.json":this.dataApi+"/nl_en.json";
    this.httpClient.get<any>(api).subscribe(res=>{
     console.log(res);
     this.localStorageService.store('popup',res);

     let  nes=this.localStorageService.retrieve('popup');
     if(nes.visible){
      this.news=res;
     }
     
   })
   }
}
