import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import * as AOS from 'aos';
import { LanguageHelper } from 'app/shared/language/language.helper';
import { Http, Headers, Response, URLSearchParams,RequestOptions } from '@angular/http';
import { HttpClient, HttpHeaders } from "@angular/common/http";
var easeInOutQuad = function (t, b, c, d) {
  t /= d/2;
    if (t < 1) return c/2*t*t + b;
    t--;
    return -c/2 * (t*(t-2) - 1) + b;
};
@Component({
  selector: 'app-headers',
  templateUrl: './headers.component.html',
  styleUrls: ['./headers.component.scss']
})
export class HeadersComponent implements OnInit {
   private dynamicDatApi:string;
   private dataApi:string="assets/datajsons"
   

    sliders=[];
    mediaData:any;
    isRTL:any;//chnage direction variable
    icon_video_button_class: string = 'fa-play';
    text_video_button: string = 'Play Video';

  constructor( private languageHelper:LanguageHelper,
        private httpClient:HttpClient,private router:Router) {
     this.isRTL = this.languageHelper.currentLang().isRTL;
   }

    media=[1,2,3,4,5,6,7,8,9,1,2,3,4,5,6,7,8,9,1,2,3,4,5,6,7,8,9,1,2,3,4,5,6,7,8,9]

   medias=[]

  ngOnInit() {
    AOS.init();

            console.log(this.languageHelper.currentLang());
        
       
         this.isRTL = this.languageHelper.currentLang().isRTL;
         this.dynamicDatApi=this.isRTL?"./assets/i18n/ar.json":"./assets/i18n/en.json";
         console.log("dynamic api",this.dynamicDatApi);
      
      this.getData(this.dynamicDatApi);
      this.getMediaData();
  } 

  playBackgroundVideo(event){
      var id_video = document.getElementById('video-source') as HTMLVideoElement;
      var parent = event.target.parentElement.parentElement;
    if(id_video.paused){
         id_video.play();
        this.text_video_button = 'Pause Video';
        this.icon_video_button_class = 'fa-pause';
     } else {
         id_video.pause();
        this.text_video_button = 'Play Video';
        this.icon_video_button_class = 'fa-play';
     }
  }


  //labgyage change in header
  getAllLangs() {
    return this.languageHelper.getAllLanguages();
}

changeLang(lang) {
    // alert();
    this.languageHelper.changeLang(lang);
    window.location.reload();
    
  

}



 getData(api){

     this.httpClient.get<any>(api).subscribe(res=>{
         console.log(res);
         this.sliders=res.SLIDERS;

     })

 }


 //getMediaData
 getMediaData(){
 
   this.httpClient.get<any>(`${this.dataApi}/media.json`).subscribe(res=>{
     console.log("rs",res);
     this.mediaData=res;

   })
 }

 smoothScroll(target) {
  var targetScroll = document.getElementById(target);
  this.scrollTo(document.scrollingElement || document.documentElement, targetScroll.offsetTop, 1250);
}
scrollTo(element, to, duration) {
  var start = element.scrollTop,
      change = to - start,
      currentTime = 0,
      increment = 20;

  var animateScroll = function(){
      currentTime += increment;
      var val = easeInOutQuad(currentTime, start, change, duration);
      element.scrollTop = val;
      if(currentTime < duration) {
          setTimeout(animateScroll, increment);
      }
  };
  animateScroll();
}
gotoHeroes() {

  this.router.navigate(['/media']);
  location.reload();

}
}
