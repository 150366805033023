import { Component, OnInit } from '@angular/core';
import * as Rellax from 'rellax';
import { LanguageHelper } from 'app/shared/language/language.helper';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Router } from '@angular/router';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { Http, Headers, Response, URLSearchParams,RequestOptions } from '@angular/http';
import { ToastrManager } from 'ng6-toastr-notifications';
var easeInOutQuad = function (t, b, c, d) {
  t /= d/2;
    if (t < 1) return c/2*t*t + b;
    t--;
    return -c/2 * (t*(t-2) - 1) + b;
};
declare const $: any;

@Component({
  selector: 'app-job-career',
  templateUrl: './job-career.component.html',
  styleUrls: ['./job-career.component.scss']})
export class JobCareerComponent implements OnInit {
  private dataApi:string="assets/datajsons/jobs";
jobs=[]
    isRTL:any;
    closeResult: string;
 //varribale relate to mail send
 email:any;
 value:any;
 femail='s.sultan7775@gmail.com';
 name:any;
 subject:any;
 firstName:any;
 lastName:any;
 file:any;
 fileString:any;
 english:any;
 arabic:any;
 fileObj={
 filename:'',
 content: '',
 
 encoding: 'base64'}
    constructor( private languageHelper:LanguageHelper,
      private httpClient:HttpClient,private router:Router,
      private modalService: NgbModal,
      private http:Http,public toastr: ToastrManager) {
   this.isRTL = this.languageHelper.currentLang().isRTL;
 }
 open(content, type, modalDimension) {
  if (modalDimension === 'sm' && type === 'modal_mini') {
      this.modalService.open(content, { windowClass: 'modal-mini modal-primary', size: 'sm' }).result.then((result) => {
          this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
  } else if (modalDimension === 'md' && type === 'Login') {
    this.modalService.open(content, { windowClass: 'modal-login modal-primary' }).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  } else {
      this.modalService.open(content).result.then((result) => {
          this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
  }

}
private getDismissReason(reason: any): string {
  if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
  } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
  } else {
      return  `with: ${reason}`;
  }
}
  ngOnInit() {
    this.getJobs()
    this.getAllLangs();
    var rellaxHeader = new Rellax('.rellax-header');

      var body = document.getElementsByTagName('body')[0];
      body.classList.add('contact-page');
      var navbar = document.getElementsByTagName('nav')[0];
      navbar.classList.add('navbar-transparent');


  }
  Show(){


    $(function unfoldPost() {
      var actionButton = $(".material-card-action a");
      
      actionButton.on("click", function(e) {
        e.preventDefault();
        
        $(this).closest(".material-card").toggleClass("triggered");
        $(this).closest(".material-card-action").toggleClass("triggered");
        
      });
    });
  }
  ngOnDestroy(){
      var body = document.getElementsByTagName('body')[0];
      body.classList.remove('contact-page');
      var navbar = document.getElementsByTagName('nav')[0];
      navbar.classList.remove('navbar-transparent');

  }

  sendEmail() {
    this.name=this.firstName+" "+this.lastName;
    let url = `https://us-central1-amnwwafaa-82e37.cloudfunctions.net/sendMail`
  
    let headers = new Headers({'Content-Type': 'application/json' });
    let options: RequestOptions = new RequestOptions({ headers: headers });
   
  
    let body={
      "dest":this.email?this.email:'',
      "org" : this.femail?this.femail:'',
      "subject" :this.value?this.value:'',
      "name" :this.name?this.name:'',
      "fileObj":this.fileObj?this.fileObj:{},
      "requestType":"job"
    }
    console.log("body",body);
    return this.http.post(url, body, options)
                    .subscribe(res => {
                      console.log(res)
                      this.showSuccess()
                    },err=>{

                    })
                    
 
  
  }

  onFileChange(event){
    console.log(event)
    let myReader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
       this.file = event.target.files[0];
       this.fileObj['filename']=this.file.name;
      myReader.onload = this._handleReaderLoaded.bind(this);
      myReader.readAsBinaryString(this.file);
    }
  }
  _handleReaderLoaded(readerEvt) {
    // console.log(1)
    
    var binaryString = readerEvt.target.result;
    let base64textString = btoa(binaryString);
    let filevalue = btoa(binaryString);
    this.fileString=filevalue;
    this.fileObj['content']=this.fileString;

}
showSuccess() {
  this.toastr.successToastr('This is success toast.', 'Success!');
}
smoothScroll(target) {
  var targetScroll = document.getElementById(target);
  this.scrollTo(document.scrollingElement || document.documentElement, targetScroll.offsetTop, 1250);
}
scrollTo(element, to, duration) {
  var start = element.scrollTop,
      change = to - start,
      currentTime = 0,
      increment = 20;

  var animateScroll = function(){
      currentTime += increment;
      var val = easeInOutQuad(currentTime, start, change, duration);
      element.scrollTop = val;
      if(currentTime < duration) {
          setTimeout(animateScroll, increment);
      }
  };
  animateScroll();
}
changeLang(lang) {
  // alert();
  this.languageHelper.changeLang(lang);
  window.location.reload();
  


}


//
 //labgyage change in header
 getAllLangs() {
  console.log("this.languageHelper.getAllLanguages();",this.languageHelper.getAllLanguages())
  let arr=this.languageHelper.getAllLanguages();
  this.english=arr[0];
  this.arabic=arr[1];
  return this.languageHelper.getAllLanguages();
}


getJobs(){
 let api=this.isRTL?this.dataApi+"/jobs_ar.json":this.dataApi+"/jobs_en.json";
 this.httpClient.get<any>(api).subscribe(res=>{
  console.log(res);
  this.jobs=res;
})
}
}
