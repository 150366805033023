import { CoomingComponent } from './cooming/cooming.component';
import { HqComponent } from './hq/hq.component';
import { FeedBackComponent } from './feed-back/feed-back.component';
import { JobCareerComponent } from './job-career/job-career.component';
import { MediaComponent } from './media/media.component';
import { HeadersComponent } from './sections/headers/headers.component';
import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule  } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';
import { SectionsComponent } from './sections/sections.component';
const routes: Routes =[
    { path: '', redirectTo: 'Cooming', pathMatch: 'full' },

    { path: 'sections',             component: SectionsComponent },
    { path: 'media',             component: MediaComponent },
    { path: 'job-career',             component: JobCareerComponent },
    { path: 'feed-back',             component: FeedBackComponent },
    { path: 'hq',             component: HqComponent },
    { path: 'Cooming',             component: CoomingComponent },




];

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        RouterModule.forRoot(routes,{useHash:true})
    ],
    exports: [
    ],
})
export class AppRoutingModule { }
