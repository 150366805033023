import { MediaModule } from './media/media.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; // this is needed!
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app.routing';
import { SectionsModule } from './sections/sections.module';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppComponent } from './app.component';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { HttpModule } from '@angular/http';
import { HttpClientModule, HttpClient, HttpHeaders } from '@angular/common/http';
import {Ng2Webstorage} from 'ngx-webstorage';
import { LanguageHelper } from './shared/language/language.helper';
import { JobCareerComponent } from './job-career/job-career.component';
import { FeedBackComponent } from './feed-back/feed-back.component';
import { NgImageSliderModule } from 'ng-image-slider';
import { HqComponent } from './hq/hq.component';
//language changes
// export function HttpLoaderFactory(http: HttpClient) {
//     return new TranslateHttpLoader(http, './assets/i18n/', '.json');
//   }
import { AgmCoreModule } from '@agm/core';
import { CoomingComponent } from './cooming/cooming.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
    declarations: [
        AppComponent,
        NavbarComponent,
        JobCareerComponent,
        FeedBackComponent,
        HqComponent,
        CoomingComponent,
    ],
    imports: [
        BrowserAnimationsModule,
        AgmCoreModule.forRoot({
          apiKey: 'AIzaSyDLighMISUlDdfJn4IswlxZRSICOHi1SEk'
      }),
        HttpModule,
        HttpClientModule,
        NgbModule.forRoot(),
        FormsModule,
        RouterModule,
        AppRoutingModule,
        SectionsModule,
        NgImageSliderModule,
        Ng2Webstorage.forRoot(),
        TranslateModule.forRoot({
            loader: {
              provide: TranslateLoader,
              useFactory: HttpLoaderFactory,
              deps: [HttpClient]
            }
          }),
      
          MediaModule
    ],
    providers: [LanguageHelper],
    bootstrap: [AppComponent]
})
export class AppModule { }
