import { Component, OnInit, OnDestroy, HostListener, AfterViewInit ,Inject} from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService,SessionStorageService} from 'ngx-webstorage';
import { LanguageHelper } from './../shared/language/language.helper';
import { DOCUMENT } from '@angular/platform-browser';
import { Http, Headers, Response, URLSearchParams,RequestOptions } from '@angular/http';
import { HttpClient, HttpHeaders } from "@angular/common/http";
var easeInOutQuad = function (t, b, c, d) {
    t /= d/2;
      if (t < 1) return c/2*t*t + b;
      t--;
      return -c/2 * (t*(t-2) - 1) + b;
  };
@Component({
    selector: 'app-sections',
    templateUrl: './sections.component.html',
    styleUrls: ['./sections.component.scss']
})
export class SectionsComponent implements OnInit {
    private fragment: string;
    private dataApi:string="assets/datajsons/newsletter";
    sliders=[];
    isRTL:any;//chnage direction variable
    news:any;
    constructor(router: Router,private languageHelper:LanguageHelper,
        private translateService:TranslateService,
        private localStorageService:LocalStorageService,@Inject(DOCUMENT,) private document: any,private httpClient:HttpClient ) {
        router.events.subscribe(s => {
            if (s instanceof NavigationEnd) {
                const tree = router.parseUrl(router.url);
                if (tree.fragment) {
                    const element = document.querySelector("#" + tree.fragment);
                    if (element) { element.scrollIntoView(); }
                }
            }
        });
    }

    ngOnInit() {
        let  nes=this.localStorageService.retrieve('popup');
        console.log("res",nes)
       
        if(nes){
            this.news=nes;
           
        }else{
            this.getNews();
        }
        // if(nes.visible){
        // var navbar = document.getElementsByTagName('app-navbar')[0];
        // navbar.children[0].classList.remove('navbar-transparent');

     

    }
    // ngOnDestroy() {
    //     var navbar = document.getElementsByTagName('app-navbar')[0];
    //     navbar.children[0].classList.add('navbar-transparent');
    // }

    smoothScroll(target) {
        var targetScroll = document.getElementById(target);
        this.scrollTo(document.scrollingElement || document.documentElement, targetScroll.offsetTop, 1250);
    }
    scrollTo(element, to, duration) {
        var start = element.scrollTop,
            change = to - start,
            currentTime = 0,
            increment = 20;

        var animateScroll = function(){
            currentTime += increment;
            var val = easeInOutQuad(currentTime, start, change, duration);
            element.scrollTop = val;
            if(currentTime < duration) {
                setTimeout(animateScroll, increment);
            }
        };
        animateScroll();
    }

// //update the direction
// updateDir(isRTL){
//     if (isRTL) {
//         this.document.getElementsByTagName('html')[0].setAttribute('dir', 'rtl');
//     } else {
//         this.document.getElementsByTagName('html')[0].setAttribute('dir', 'ltr');            
//     }
// }
getNews(){
    let api=this.isRTL?this.dataApi+"/nl_ar.json":this.dataApi+"/nl_en.json";
    this.httpClient.get<any>(api).subscribe(res=>{
     console.log(res);
     this.localStorageService.store('popup',res);

    
      this.news=res;
     
     
   })
   }
}
