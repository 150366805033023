import { Component, OnInit ,ViewChild} from '@angular/core';
import * as AOS from 'aos';
import { LanguageHelper } from 'app/shared/language/language.helper';
import { Http, Headers, Response, URLSearchParams,RequestOptions } from '@angular/http';
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {

  private dataApi:string="assets/datajsons/services";
  mediaData:any;
  constructor( private languageHelper:LanguageHelper,
    private httpClient:HttpClient) {
    }

  ngOnInit() {
    this.getMediaData();
    
  }

  //getMediaData
getMediaData(){
  let dat=this.languageHelper.currentLang().isRTL;

if(dat){
  this.httpClient.get<any>(`${this.dataApi}/service_ar.json`).subscribe(res=>{
    console.log("rs",res);
    this.mediaData=res;

  })
}else{
  this.httpClient.get<any>(`${this.dataApi}/service_en.json`).subscribe(res=>{
    console.log("rs",res);
    this.mediaData=res;

  })
}

}
}
