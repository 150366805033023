import { Component, OnInit } from '@angular/core';
import { MouseEvent } from '@agm/core';
import { Http, Headers, Response, URLSearchParams,RequestOptions } from '@angular/http';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { LanguageHelper } from './../../shared/language/language.helper';

import 'rxjs/add/operator/toPromise';
// just an interface for type safety.
import { ToastrManager } from 'ng6-toastr-notifications';
var easeInOutQuad = function (t, b, c, d) {
  t /= d/2;
    if (t < 1) return c/2*t*t + b;
    t--;
    return -c/2 * (t*(t-2) - 1) + b;
};
interface marker {
	lat: number;
	lng: number;
	label?: string;
	draggable: boolean;
}
declare const google: any;

@Component({
  selector: 'app-branch',
  templateUrl: './branch.component.html',
  styleUrls: ['./branch.component.scss']
})
export class BranchComponent implements OnInit {
  private dataApi:string="assets/datajsons/locations";
  private api:any;
  zoom: number = 18;
  lat: number = 24.673710;
  lng: number = 46.733766;
  isRTL:any;
  styles: any[] = [{"featureType":"water","elementType":"geometry","stylers":[{"color":"#e9e9e9"},{"lightness":17}]},{"featureType":"landscape","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":20}]},{"featureType":"road.highway","elementType":"geometry.fill","stylers":[{"color":"#ffffff"},{"lightness":17}]},{"featureType":"road.highway","elementType":"geometry.stroke","stylers":[{"color":"#ffffff"},{"lightness":29},{"weight":0.2}]},{"featureType":"road.arterial","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":18}]},{"featureType":"road.local","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":16}]},{"featureType":"poi","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":21}]},{"featureType":"poi.park","elementType":"geometry","stylers":[{"color":"#dedede"},{"lightness":21}]},{"elementType":"labels.text.stroke","stylers":[{"visibility":"on"},{"color":"#ffffff"},{"lightness":16}]},{"elementType":"labels.text.fill","stylers":[{"saturation":36},{"color":"#333333"},{"lightness":40}]},{"elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"transit","elementType":"geometry","stylers":[{"color":"#f2f2f2"},{"lightness":19}]},{"featureType":"administrative","elementType":"geometry.fill","stylers":[{"color":"#fefefe"},{"lightness":20}]},{"featureType":"administrative","elementType":"geometry.stroke","stylers":[{"color":"#fefefe"},{"lightness":17},{"weight":1.2}]}];
  focus;
  focus1;
  focus2;
  focus3;
  focus4;
  panelOpenState = false;
  branch:any;
  

  constructor(private languageHelper:LanguageHelper,public toastr: ToastrManager,private httpClient:HttpClient) {
    this.isRTL = this.languageHelper.currentLang().isRTL;

   }
 // google maps zoom level


clickedMarker(label: string, index: number) {
  console.log(`clicked the marker: ${label || index}`)
}

mapClicked($event: MouseEvent) {
  this.markers.push({
    lat: $event.coords.lat,
    lng: $event.coords.lng,
    draggable: true
  });
}

markerDragEnd(m: marker, $event: MouseEvent) {
  console.log('dragEnd', m, $event);
}

markers: marker[] = [
  {
    lat: 51.673858,
    lng: 7.815982,
    label: 'A',
    draggable: true
  },
  {
    lat: 51.373858,
    lng: 7.215982,
    label: 'B',
    draggable: false
  },
  {
    lat: 51.723858,
    lng: 7.895982,
    label: 'C',
    draggable: true
  }
]




  ngOnInit() {
    this.getBranches();
    for(var data in this.branch){
      let obj={ 
        lat: this.branch[data].lat,
        lng: this.branch[data].lng,
        label: this.branch[data].name.charAt(0),
        draggable: true
      }
      let mark=[];
      mark.push(obj);
      this.branch[data]['markers']=mark

    }

    console.log("data",this.branch);
    // var myLatlng = new google.maps.LatLng(24.673710, 46.733766);
    // var mapOptions = {
    //   // zoom: 14,
    //   center: myLatlng,
    //   styles: [{"featureType":"water","elementType":"geometry","stylers":[{"color":"#e9e9e9"},{"lightness":17}]},{"featureType":"landscape","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":20}]},{"featureType":"road.highway","elementType":"geometry.fill","stylers":[{"color":"#ffffff"},{"lightness":17}]},{"featureType":"road.highway","elementType":"geometry.stroke","stylers":[{"color":"#ffffff"},{"lightness":29},{"weight":0.2}]},{"featureType":"road.arterial","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":18}]},{"featureType":"road.local","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":16}]},{"featureType":"poi","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":21}]},{"featureType":"poi.park","elementType":"geometry","stylers":[{"color":"#dedede"},{"lightness":21}]},{"elementType":"labels.text.stroke","stylers":[{"visibility":"on"},{"color":"#ffffff"},{"lightness":16}]},{"elementType":"labels.text.fill","stylers":[{"saturation":36},{"color":"#333333"},{"lightness":40}]},{"elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"transit","elementType":"geometry","stylers":[{"color":"#f2f2f2"},{"lightness":19}]},{"featureType":"administrative","elementType":"geometry.fill","stylers":[{"color":"#fefefe"},{"lightness":20}]},{"featureType":"administrative","elementType":"geometry.stroke","stylers":[{"color":"#fefefe"},{"lightness":17},{"weight":1.2}]}],
    //   scrollwheel: false, //we disable de scroll over the map, it is a really annoing when you scroll through page
    // }
    
    // var map = new google.maps.Map(document.getElementById("contactUsMap2"), mapOptions);
    
    // var marker = new google.maps.Marker({
    //     position: myLatlng,
    //     title:"Suhaib Sultan Office"
    // });
    
    // // To add the marker to the map, call setMap();
    // marker.setMap(map);


  }


  collapsee(branchs){
    branchs.selected=!branchs.selected;

  }
  smoothScroll(target) {
    var targetScroll = document.getElementById(target);
    this.scrollTo(document.scrollingElement || document.documentElement, targetScroll.offsetTop, 1250);
}
scrollTo(element, to, duration) {
    var start = element.scrollTop,
        change = to - start,
        currentTime = 0,
        increment = 20;

    var animateScroll = function(){
        currentTime += increment;
        var val = easeInOutQuad(currentTime, start, change, duration);
        element.scrollTop = val;
        if(currentTime < duration) {
            setTimeout(animateScroll, increment);
        }
    };
    animateScroll();
}

getBranches(){
  this.api=this.isRTL?this.dataApi+"/location_ar.json":this.dataApi+"/location_en.json";
  this.httpClient.get<any>(`${this.api}`).subscribe(res=>{
     console.log("rs",res);
     this.branch=res.branch;
    for(var data in this.branch){
        let obj={ 
          lat: this.branch[data].lat,
          lng: this.branch[data].lng,
          label: this.branch[data].name.charAt[0],
          draggable: true
        }
        let mark=[];
        mark.push(obj);
        this.branch[data]['markers']=mark
      }
      
  })
  
}
}
