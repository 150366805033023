import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cooming',
  templateUrl: './cooming.component.html',
  styleUrls: ['./cooming.component.scss']
})
export class CoomingComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
